import React from "react"

const AboutPage = () => (
  <main>
    <h1>It's me, deeje!</h1>
    <p>Many things to come.</p>
  </main>
)

export default AboutPage
